import { shiftColorCodeEnum } from '@jobdone/jobdone-shared-library';
import { makeStyles, alpha } from '@material-ui/core';
const blueColorOverlayColor = '#7CA6F4';
const useStyles = makeStyles((theme) => ({
    container: ({ color = '', isBlocked }) => ({
        position: 'relative',
        borderRadius: '8px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        userSelect: 'none',
        border: `1px solid ${isBlocked ? theme.palette.grey[300] : color}`,
        marginBottom: 1,
    }),
    isHover: ({ color = '' }) => ({
        '&:hover': {
            transition: theme.transitions.create('border'),
            border: `1px dashed ${color}`,
        },
        '&:hover $overlay': {
            opacity: '1',
            transition: theme.transitions.create('opacity'),
        },
    }),
    overlay: ({ color = '' }) => ({
        boxSizing: 'border-box',
        opacity: '0',
        position: 'absolute',
        borderRadius: '8px',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1,
        background: color === shiftColorCodeEnum.BLUE ? alpha(blueColorOverlayColor, 0.9) : alpha(color, 0.9),
        color: theme.palette.common.white,
    }),
    oneWeekOverlay: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
    },
    twoWeeksOverlay: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    oneMonthOverlay: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    overlayButton: {
        border: `2px solid ${theme.palette.common.white}`,
        borderRadius: '8px',
        background: 'transparent',
        color: theme.palette.common.white,
        cursor: 'pointer',
        transition: theme.transitions.create(['background', 'color']),
        '&:hover': {
            background: alpha(theme.palette.common.white, 0.8),
            borderColor: 'transparent',
            color: ({ color = '' }) => (color === shiftColorCodeEnum.BLUE ? alpha(blueColorOverlayColor, 0.9) : alpha(color, 0.9)),
        },
        '&:active': {
            background: theme.palette.common.white,
            color: ({ color = '' }) => (color === shiftColorCodeEnum.BLUE ? alpha(blueColorOverlayColor, 0.9) : alpha(color, 0.9)),
        },
        lineHeight: '0 !important',
    },
    twoWeeksOverlayButton: {
        padding: '1px 2px',
        '& svg': {
            width: '20px !important',
            height: '20px !important',
        },
        lineHeight: '0 !important',
    },
    oneMonthOverlayButton: {
        padding: 0,
        width: '100%',
        height: '100%',
        background: 'transparent',
        color: theme.palette.common.white,
        cursor: 'pointer',
        border: 'none',
        '& svg': {
            width: '18px !important',
            height: '18px !important',
        },
        lineHeight: '0 !important',
    },
    topContainer: ({ color = '', hasFlash = false, selected = false, isFlashMode = false, }) => ({
        background: alpha(color, 0.1),
        display: 'flex',
        borderTopLeftRadius: hasFlash || selected || isFlashMode ? 4 : 8,
        borderTopRightRadius: hasFlash || selected || isFlashMode ? 4 : 8,
    }),
    oneWeekTopContainer: {
        paddingRight: 2,
        height: 20,
        alignItems: 'center',
        gap: '4px',
    },
    twoWeeksTopContainer: {
        paddingRight: 2,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& $shiftIcon': {
            textAlign: 'left',
        },
    },
    oneMonthTopContainer: {
        justifyContent: 'center',
    },
    oneMonthCompactTopContainer: {
        justifyContent: 'left',
        paddingLeft: 2,
    },
    twoWeeksBlockedIcon: {
        textAlign: 'left',
        color: ({ color = '' }) => color,
    },
    weekCompactView: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: 8,
    },
    oneWeekCompactResourceView: {
        height: 40,
    },
    twoWeekCompactResourceView: {
        height: 60,
    },
    compactViewIconAndTime: {
        display: 'flex',
        alignItems: 'center',
        gap: 4,
    },
    compactViewBlockedIcon: {
        '& svg': {
            color: ({ color = '' }) => color,
        },
    },
    compactViewOverlayButton: {
        justifyContent: 'center',
        gap: 8,
        '& button': {
            width: 24,
            height: 24,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& svg': {
                width: 18,
                height: 18,
            },
        },
    },
    bottomContainer: ({ color = '', hasFlash = false, selected = false, isFlashMode = false, }) => ({
        background: alpha(color, 0.1),
        borderBottomLeftRadius: hasFlash || selected || isFlashMode ? 4 : 8,
        borderBottomRightRadius: hasFlash || selected || isFlashMode ? 4 : 8,
        height: 20,
        display: 'flex',
        alignItems: 'center',
        '& > *': {
            color,
        },
    }),
    twoWeeksResourceViewBottomContainer: ({ color = '', hasFlash = false, selected = false, isFlashMode = false, }) => ({
        background: alpha(color, 0.1),
        borderBottomLeftRadius: hasFlash || selected || isFlashMode ? 4 : 8,
        borderBottomRightRadius: hasFlash || selected || isFlashMode ? 4 : 8,
        display: 'flex',
        flexDirection: 'column',
        '& > *': {
            color,
        },
    }),
    oneMonthResourceViewBottomContainer: ({ color = '', hasFlash = false, selected = false, isFlashMode = false, }) => ({
        background: alpha(color, 0.1),
        borderBottomLeftRadius: hasFlash || selected || isFlashMode ? 4 : 8,
        borderBottomRightRadius: hasFlash || selected || isFlashMode ? 4 : 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            color,
        },
    }),
    oneWeekBottomContainer: {
        padding: '0px 8px',
        justifyContent: 'space-between',
    },
    oneWeekBottomResourceView: {
        width: 200,
        marginLeft: 2,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        color: `${theme.palette.grey[700]} !important`,
    },
    twoWeekBottomResourceView: {
        width: 50,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        marginTop: 0,
        marginBottom: 0,
        padding: 0,
        color: `${theme.palette.grey[700]} !important`,
    },
    twoWeeksBottomContainer: {
        padding: '0px 4px',
        justifyContent: 'flex-start',
        '& > span': {
            color: theme.palette.common.black,
        },
    },
    oneMonthBottomContainer: {
        justifyContent: 'center',
        textAlign: 'center',
    },
    oneMonthCompactBottomContainer: {
        justifyContent: 'left',
        textAlign: 'left',
        padding: 2,
    },
    compactTimeContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    compactTimeContainerOneMonth: {
        paddingTop: 4,
        textAlign: 'left',
    },
    compactTimeMinute: {
        transform: 'translateY(-2px)',
    },
    compactTimeEntry: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    shiftWithBreakOneWeekContainer: ({ color = '', }) => ({
        paddingLeft: 20,
        background: alpha(color, 0.1),
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
    }),
    oneMonthBlockedIcon: ({ color = '' }) => ({
        position: 'absolute',
        content: '""',
        bottom: 0,
        right: 0,
        color,
        backgroundColor: theme.palette.grey[300],
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        borderTopRightRadius: 4,
    }),
    isBlocked: {
        '& $topContainer, & $bottomContainer, & $shiftWithBreakOneWeekContainer': {
            background: theme.palette.grey[100],
        },
        '& $unPublished': {
            background: `repeating-linear-gradient( -50deg, ${theme.palette.grey[500]}, ${theme.palette.grey[500]} 20px, transparent 20px, transparent 36px )`,
        },
    },
    shiftNameBlock: {
        width: '80%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    compactViewShiftName: ({ color = '' }) => ({
        color,
        marginRight: 4,
        textAlign: 'right',
        fontWeight: 500,
        fontSize: '0.8rem',
    }),
    twoWeekViewShiftNameBlock: ({ color }) => ({
        color,
        textAlign: 'right',
        fontWeight: 500,
        textTransform: 'uppercase',
        fontSize: '0.8rem',
    }),
    oneMonthShiftNameBlock: {
        textTransform: 'uppercase',
    },
    shiftIcon: ({ color }) => ({
        '& > svg': {
            width: 20,
            height: 20,
        },
        color,
    }),
    unPublished: ({ color = '' }) => ({
        position: 'absolute',
        borderRadius: 8,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        opacity: '0.1',
        background: `repeating-linear-gradient( -50deg, ${color}, ${color} 20px, transparent 20px, transparent 36px )`,
    }),
    flash: {
        '--angle': '0deg',
        border: '4px solid transparent !important',
        boxSizing: 'border-box',
        backgroundOrigin: 'border-box',
        backgroundImage: 'conic-gradient(from var(--angle), #3C5AF9 0%, #F96955 4.69%, #FD87AA 8.85%, #FCD5AD 16.15%, #E97584 23.96%, #FED556 31.77%, #FED655 40.63%, #FFF4F2 48.44%, #F2CDFE 54.69%, #FD99F9 63.54%,  #D693FA 69.79%, #E492E4 77.08%, #1E9AFA 83.85%, #8CFBD7 90.63%, #2E50F6 100%)',
        boxShadow: `2px 1000px 1px ${theme.palette.common.white} inset`,
        animation: '$rotate 2s linear infinite',
    },
    isFlashMode: {
        '& $overlay': {
            background: 'rgba(224, 0, 11, 0.85)',
            margin: 2,
            borderRadius: 4,
            width: 'calc(100% - 4px)',
            height: 'calc(100% - 4px)',
            gap: '2px',
            cursor: 'pointer',
        },
        '&:hover': {
            '--angle': '0deg',
            border: '4px solid transparent !important',
            boxSizing: 'border-box',
            backgroundOrigin: 'border-box',
            backgroundImage: 'conic-gradient(from var(--angle), #3C5AF9 0%, #F96955 4.69%, #FD87AA 8.85%, #FCD5AD 16.15%, #E97584 23.96%, #FED556 31.77%, #FED655 40.63%, #FFF4F2 48.44%, #F2CDFE 54.69%, #FD99F9 63.54%,  #D693FA 69.79%, #E492E4 77.08%, #1E9AFA 83.85%, #8CFBD7 90.63%, #2E50F6 100%)',
            boxShadow: `2px 1000px 1px ${theme.palette.common.white} inset`,
            animation: '$rotate 2s linear infinite',
        },
        '&:hover $unPublished': {
            background: 'none',
        },
    },
    '@keyframes rotate': {
        to: {
            '--angle': '360deg',
        },
    },
    trashIcon: {
        height: 18,
        width: 18,
        '& svg': {
            width: 18,
            height: 18,
        },
    },
    deleteLabel: {
        fontWeight: 700,
        fontSize: '13px',
        lineHeight: '16px',
    },
    selected: {
        padding: 2,
        border: `2px solid ${theme.palette.primary.main}`,
        boxSizing: 'border-box',
    },
    skeletonBlock: {
        borderRadius: 8,
    },
    oneWeekSkeletonBlock: {
        minHeight: '24px',
    },
    twoWeekSkeletonBlock: {
        minHeight: '48px',
    },
    oneMonthSkeletonBlock: {
        minHeight: '46px',
    },
    assignedUserAvatarBlock: ({ color = '' }) => ({
        border: `1px solid ${color}`,
        marginLeft: 2,
    }),
    confirmationModeBlock: {
        position: 'absolute',
        zIndex: 1,
        padding: 0.5,
        maxHeight: 17,
        top: '-10px',
        left: '-6px',
        lineHeight: '0 !important',
        '& svg': {
            background: theme.palette.common.white,
            borderRadius: '50%',
            width: 16,
            height: 16,
        },
    },
    confirmationRejected: {
        '& svg path': {
            color: '#FF333D',
        },
    },
    timeNote: {
        color: theme.palette.grey[500],
    },
    grey700: {
        color: theme.palette.grey[700],
    },
    breakDurationInMinute: {
        verticalAlign: 'text-top',
    },
    verticalLine: ({ color, breaksLength }) => ({
        width: 1,
        height: breaksLength ? (20 * breaksLength) - 5 : 0,
        borderRadius: 1,
        backgroundColor: alpha(String(color), 0.8),
    }),
    compactViewWithBreaksTimeNote: {
        minWidth: 'fit-content',
        paddingRight: 5,
        fontSize: 12,
    },
    marginRight: {
        marginRight: '10px !important',
    },
    paddingRight: {
        paddingRight: '10px !important',
    },
    differentFromTemplate: {
        height: 5,
        width: 5,
        background: theme.palette.grey[500],
        borderRadius: '50%',
        position: 'absolute',
        top: 3,
        right: 3,
    },
}));
export default useStyles;
