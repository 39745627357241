import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useEffect } from 'react';
import cx from 'classnames';
import { shiftColorCodeEnum, shiftIconEnum } from '@jobdone/jobdone-shared-library';
import useStyles from './ShiftEntry.styles';
import { Body, Caption, Text } from '../../../Typography';
import { Briefcase, EditAlt, MoreHorizontal, ArrowUpRight, Car, Headphones, Home, Moon, PhoneAlt, Star, Sun, SunHaze, Users, Trash, ShiftBlocked, } from '../../../icons';
import { Tooltip } from '../../../Tooltip';
import { ShiftEntryTypes } from '../../commons/enums';
import { getAbbreviatedName, useDropdownMenu } from '../../../../utils/helpers';
import { EntryMenuItems, shiftMenuItems } from '../..';
import { Skeleton } from '../../../Skeleton';
import { ConfirmationStatusEnum, rangePickerVariantEnum } from '../../../../utils';
import { Icons } from '../../../..';
export const ShiftEntry = ({ testId, entryType = 'basic', isPublished = false, title = 'Shift 1', time = '7:00 - 11:00', timeNote, color = shiftColorCodeEnum.BLUE, className, icon = shiftIconEnum.BRIEFCASE, blockedTitle, onEditClick, onDeleteClick, menuItems, isOpenShift = false, showHoverActions = true, hasFlash, selected, isFlashMode = false, labels = {
    deleteLabel: 'Delete',
}, loading, view = rangePickerVariantEnum['1_WEEK'], isCompact = false, isCompactWithBreak = false, assignedUserAvatar, assignedUserFullName, isResourceView, isConfirmationMode, confirmationStatus, breakDurationInMinuteLocale, breaks, firstName, lastName, isDifferentFromTemplate = false, abbreviation = '', ...rest }) => {
    const classes = useStyles({
        color,
        hasFlash,
        selected,
        isFlashMode,
        isBlocked: entryType === ShiftEntryTypes.isBlocked,
        breaksLength: breaks?.length,
    });
    const { handleClose: handleCloseMore, anchorEl, handleClick: handleClickMore } = useDropdownMenu();
    const [shiftFromTime, shiftToTime] = time.split(' - ');
    const shiftFromTimeRawHour = shiftFromTime.split(':')[0];
    const shiftFromTimeHour = shiftFromTimeRawHour[0] === '0' ? shiftFromTimeRawHour[1] : shiftFromTimeRawHour;
    const shiftFromTimeMinute = shiftFromTime.split(':')[1];
    const shiftToTimeRawHour = shiftToTime.split(':')?.[0];
    const shiftToTimeHour = shiftToTimeRawHour[0] === '0' ? shiftToTimeRawHour[1] : shiftToTimeRawHour;
    const shiftToTimeMinute = shiftToTime.split(':')[1];
    const shiftBreaks = breaks?.map((_breakTime) => {
        let output = { duration: _breakTime.duration ? Number(_breakTime?.duration) / 60 : 0 };
        if (_breakTime.from) {
            const fromDate = new Date(_breakTime.from);
            output = {
                ...output,
                fromHour: fromDate.getHours().toString(),
                fromMinute: fromDate.getMinutes().toString().padStart(2, '0'),
            };
        }
        if (_breakTime.to) {
            const toDate = new Date(_breakTime.to);
            output = {
                ...output,
                toHour: toDate.getHours().toString(),
                toMinute: toDate.getMinutes().toString().padStart(2, '0'),
            };
        }
        return output;
    });
    useEffect(() => {
        try {
            // TS Bug: https://github.com/microsoft/TypeScript/issues/38593
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const { CSS } = window;
            CSS.registerProperty({
                name: '--angle',
                initialValue: '0deg',
                syntax: '<angle>',
                inherits: false,
            });
        }
        catch (err) {
            console.log(err);
        }
    }, []);
    if (loading) {
        return (_jsx("div", Object.assign({ className: classes.container }, { children: _jsx(Skeleton, { animation: false, variant: 'rect', className: cx(classes.skeletonBlock, {
                    [classes.oneWeekSkeletonBlock]: view === rangePickerVariantEnum['1_WEEK'],
                    [classes.twoWeekSkeletonBlock]: view === rangePickerVariantEnum['2_WEEK'],
                    [classes.oneMonthSkeletonBlock]: view === rangePickerVariantEnum['1_MONTH'],
                }) }, void 0) }), void 0));
    }
    const renderIsDifferentFromTemplate = isDifferentFromTemplate && (_jsx("div", { className: classes.differentFromTemplate }, void 0));
    const renderConfirmationIcon = () => {
        if (isConfirmationMode && confirmationStatus) {
            switch (confirmationStatus) {
                case ConfirmationStatusEnum.rejected:
                    return (_jsx("div", Object.assign({ className: cx(classes.confirmationModeBlock, classes.confirmationRejected) }, { children: _jsx(Icons.Violated, {}, void 0) }), void 0));
                case ConfirmationStatusEnum.open:
                    return (_jsx("div", Object.assign({ className: classes.confirmationModeBlock }, { children: _jsx(Icons.AwaitingApprovalCircle, {}, void 0) }), void 0));
                case ConfirmationStatusEnum.approved:
                    return (_jsx("div", Object.assign({ className: classes.confirmationModeBlock }, { children: _jsx(Icons.ApprovedCircleCheck, {}, void 0) }), void 0));
                default:
                    return _jsx(_Fragment, {}, void 0);
            }
        }
        return _jsx(_Fragment, {}, void 0);
    };
    const combineMenuItems = () => menuItems?.map((menuItem) => ({
        ...shiftMenuItems.find(({ key }) => key === menuItem.key), ...menuItem,
    })) || [];
    const cantRenderOverlay = () => entryType === ShiftEntryTypes.isCopied || !showHoverActions;
    const renderOneWeekOverlay = () => {
        if (cantRenderOverlay())
            return null;
        if (isFlashMode) {
            return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ "aria-label": 'overlay-content', "aria-hidden": true, role: 'button', className: cx(classes.overlay, classes.oneWeekOverlay, {
                        [classes.compactViewOverlayButton]: isCompact,
                    }), onClick: onDeleteClick }, { children: [_jsx(Trash, { className: classes.trashIcon }, void 0), _jsx(Text, Object.assign({ className: classes.deleteLabel }, { children: labels.deleteLabel }), void 0)] }), void 0) }, void 0));
        }
        return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ "aria-label": 'overlay-content', className: cx(classes.overlay, classes.oneWeekOverlay, {
                        [classes.compactViewOverlayButton]: isCompact,
                    }) }, { children: [_jsx("button", Object.assign({ onClick: onEditClick, className: classes.overlayButton }, { children: _jsx(EditAlt, {}, void 0) }), void 0), _jsx("button", Object.assign({ onClick: handleClickMore, className: classes.overlayButton }, { children: _jsx(MoreHorizontal, {}, void 0) }), void 0)] }), void 0), _jsx(EntryMenuItems, { handleClose: handleCloseMore, anchorEl: anchorEl, menuItems: combineMenuItems() }, void 0)] }, void 0));
    };
    const renderTwoWeeksOverlay = () => {
        if (cantRenderOverlay())
            return null;
        if (isFlashMode) {
            return (_jsx("div", Object.assign({ "aria-label": 'overlay-content', "aria-hidden": true, role: 'button', className: cx(classes.overlay, classes.twoWeeksOverlay, {
                    [classes.compactViewOverlayButton]: isCompact,
                }), onClick: onDeleteClick }, { children: _jsx(Trash, { className: classes.trashIcon }, void 0) }), void 0));
        }
        return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ "aria-label": 'overlay-content', className: cx(classes.overlay, classes.twoWeeksOverlay, {
                        [classes.compactViewOverlayButton]: isCompact,
                    }) }, { children: [_jsx("button", Object.assign({ onClick: onEditClick, className: cx(classes.overlayButton, classes.twoWeeksOverlayButton) }, { children: _jsx(EditAlt, {}, void 0) }), void 0), _jsx("button", Object.assign({ onClick: handleClickMore, className: cx(classes.overlayButton, classes.twoWeeksOverlayButton) }, { children: _jsx(MoreHorizontal, {}, void 0) }), void 0)] }), void 0), _jsx(EntryMenuItems, { handleClose: handleCloseMore, anchorEl: anchorEl, menuItems: combineMenuItems() }, void 0)] }, void 0));
    };
    const renderMonthOverlay = () => {
        if (cantRenderOverlay())
            return null;
        if (isFlashMode) {
            return (_jsx("div", Object.assign({ "aria-label": 'overlay-content', "aria-hidden": true, role: 'button', className: cx(classes.overlay, classes.oneMonthOverlay), onClick: onDeleteClick }, { children: _jsx(Trash, { className: classes.trashIcon }, void 0) }), void 0));
        }
        return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ "aria-label": 'overlay-content', className: cx(classes.overlay, classes.oneMonthOverlay) }, { children: [_jsx("button", Object.assign({ onClick: onEditClick, className: classes.oneMonthOverlayButton }, { children: _jsx(EditAlt, {}, void 0) }), void 0), _jsx("button", Object.assign({ onClick: handleClickMore, className: classes.oneMonthOverlayButton }, { children: _jsx(MoreHorizontal, {}, void 0) }), void 0)] }), void 0), _jsx(EntryMenuItems, { handleClose: handleCloseMore, anchorEl: anchorEl, menuItems: combineMenuItems() }, void 0)] }, void 0));
    };
    const renderOneWeekContent = () => {
        const renderContent = () => {
            if (isCompactWithBreak) {
                return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: cx(classes.topContainer, classes.oneWeekTopContainer, classes.weekCompactView) }, { children: [renderConfirmationIcon(), renderIsDifferentFromTemplate, _jsxs("div", Object.assign({ className: classes.compactViewIconAndTime }, { children: [entryType === ShiftEntryTypes.isBlocked
                                            ? _jsx(ShiftBlocked, { "aria-label": 'blocked-icon', className: classes.compactViewBlockedIcon }, void 0)
                                            : renderShiftIcon(), _jsxs("div", Object.assign({ className: classes.compactTimeContainer }, { children: [timeNote && (_jsx(Caption, Object.assign({ variant: 'regular', className: classes.compactViewWithBreaksTimeNote }, { children: timeNote }), void 0)), _jsxs("div", Object.assign({ className: classes.compactTimeEntry }, { children: [_jsx(Caption, Object.assign({ size: 12, variant: 'regular' }, { children: shiftFromTimeHour }), void 0), _jsx(Caption, Object.assign({ className: classes.compactTimeMinute, size: 8, variant: 'regular' }, { children: shiftFromTimeMinute }), void 0)] }), void 0), "-", _jsxs("div", Object.assign({ className: classes.compactTimeEntry }, { children: [_jsx(Caption, Object.assign({ size: 12, variant: 'regular' }, { children: shiftToTimeHour }), void 0), _jsx(Caption, Object.assign({ className: classes.compactTimeMinute, size: 8, variant: 'regular' }, { children: shiftToTimeMinute }), void 0)] }), void 0)] }), void 0)] }), void 0), _jsx(Caption, Object.assign({ className: cx(classes.shiftNameBlock, classes.compactViewShiftName, {
                                        [classes.marginRight]: isDifferentFromTemplate,
                                    }), weight: 500 }, { children: isResourceView ? (abbreviation || getAbbreviatedName(title)) : title }), void 0)] }), void 0), _jsxs("div", Object.assign({ className: classes.shiftWithBreakOneWeekContainer }, { children: [_jsx("div", { className: classes.verticalLine }, void 0), _jsx("div", { children: shiftBreaks?.map(({ fromHour, duration, fromMinute, toHour, toMinute, }) => {
                                        if (fromHour && duration && fromMinute && toHour && toMinute) {
                                            return (_jsxs("div", Object.assign({ className: classes.compactTimeContainer }, { children: [_jsxs("div", Object.assign({ className: classes.compactTimeEntry }, { children: [_jsx(Caption, Object.assign({ className: classes.grey700, size: 12, variant: 'regular' }, { children: fromHour }), void 0), _jsx(Caption, Object.assign({ className: cx(classes.compactTimeMinute, classes.grey700), size: 8, variant: 'regular' }, { children: fromMinute }), void 0)] }), void 0), "-", _jsxs("div", Object.assign({ className: classes.compactTimeEntry }, { children: [_jsx(Caption, Object.assign({ className: classes.grey700, size: 12, variant: 'regular' }, { children: toHour }), void 0), _jsx(Caption, Object.assign({ className: cx(classes.compactTimeMinute, classes.grey700), size: 8, variant: 'regular' }, { children: toMinute }), void 0)] }), void 0)] }), void 0));
                                        }
                                        return (_jsxs("div", { children: [_jsx(Caption, Object.assign({ className: classes.grey700, size: 12, variant: 'regular' }, { children: duration }), void 0), _jsx(Caption, Object.assign({ className: cx(classes.compactTimeMinute, classes.grey700, classes.breakDurationInMinute), size: 8, variant: 'regular' }, { children: breakDurationInMinuteLocale }), void 0)] }, void 0));
                                    }) }, void 0)] }), void 0)] }, void 0));
            }
            if (isCompact) {
                return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: cx(classes.topContainer, classes.oneWeekTopContainer, classes.weekCompactView) }, { children: [renderConfirmationIcon(), renderIsDifferentFromTemplate, _jsxs("div", Object.assign({ className: classes.compactViewIconAndTime }, { children: [entryType === ShiftEntryTypes.isBlocked
                                            ? _jsx(ShiftBlocked, { "aria-label": 'blocked-icon', className: classes.compactViewBlockedIcon }, void 0)
                                            : renderShiftIcon(), _jsxs("div", Object.assign({ className: classes.compactTimeContainer }, { children: [_jsxs("div", Object.assign({ className: classes.compactTimeEntry }, { children: [_jsx(Caption, Object.assign({ size: 12, variant: 'regular' }, { children: shiftFromTimeHour }), void 0), _jsx(Caption, Object.assign({ className: classes.compactTimeMinute, size: 8, variant: 'regular' }, { children: shiftFromTimeMinute }), void 0)] }), void 0), "-", _jsxs("div", Object.assign({ className: classes.compactTimeEntry }, { children: [_jsx(Caption, Object.assign({ size: 12, variant: 'regular' }, { children: shiftToTimeHour }), void 0), _jsx(Caption, Object.assign({ className: classes.compactTimeMinute, size: 8, variant: 'regular' }, { children: shiftToTimeMinute }), void 0)] }), void 0)] }), void 0)] }), void 0), _jsx(Caption, Object.assign({ className: cx(classes.shiftNameBlock, classes.compactViewShiftName, {
                                        [classes.marginRight]: isDifferentFromTemplate,
                                    }), weight: 500 }, { children: isResourceView ? (abbreviation || getAbbreviatedName(title)) : title }), void 0)] }), void 0), isResourceView && (_jsx("div", Object.assign({ className: classes.bottomContainer }, { children: _jsx(Body, Object.assign({ className: classes.oneWeekBottomResourceView, size: 12 }, { children: assignedUserFullName }), void 0) }), void 0))] }, void 0));
            }
            return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: cx(classes.topContainer, classes.oneWeekTopContainer) }, { children: [renderConfirmationIcon(), renderShiftIcon(), _jsxs(Caption, Object.assign({ variant: 'regular' }, { children: [timeNote || time, timeNote && _jsx("span", Object.assign({ className: classes.timeNote }, { children: ` ${time}` }), void 0)] }), void 0), renderIsDifferentFromTemplate] }), void 0), _jsxs("div", Object.assign({ className: cx(classes.bottomContainer, classes.oneWeekBottomContainer) }, { children: [_jsx(Caption, Object.assign({ className: classes.shiftNameBlock, variant: 'regular' }, { children: title }), void 0), entryType === ShiftEntryTypes.isBlocked && _jsx(ArrowUpRight, { "aria-label": 'blocked-icon', className: classes.blockedIcon }, void 0)] }), void 0)] }, void 0));
        };
        return (_jsxs("div", Object.assign({}, rest, { "data-testid": testId, className: cx(classes.container, {
                [classes.isHover]: !cantRenderOverlay() && showHoverActions,
                [classes.isBlocked]: entryType === ShiftEntryTypes.isBlocked,
                [`${String(className)}`]: !!className,
                [classes.flash]: hasFlash,
                [classes.selected]: selected,
                [classes.isFlashMode]: isFlashMode,
                [classes.oneWeekCompactResourceView]: isResourceView && isCompact,
            }) }, { children: [!isPublished && _jsx("div", { "aria-label": 'unpublished-entry', className: classes.unPublished }, void 0), renderOverlay(), renderContent()] }), void 0));
    };
    const renderTwoWeeksContent = () => (_jsxs("div", Object.assign({}, rest, { "data-testid": testId, className: cx(classes.container, classes.twoWeeksContainer, {
            [classes.isHover]: !cantRenderOverlay() && showHoverActions,
            [classes.isBlocked]: entryType === ShiftEntryTypes.isBlocked,
            [`${String(className)}`]: !!className,
            [classes.flash]: hasFlash,
            [classes.selected]: selected,
            [classes.isFlashMode]: isFlashMode,
            [classes.twoWeekCompactResourceView]: isResourceView && isCompact,
        }) }, { children: [renderConfirmationIcon(), renderIsDifferentFromTemplate, !isPublished && _jsx("div", { "aria-label": 'unpublished-entry', className: classes.unPublished }, void 0), renderOverlay(), _jsxs("div", Object.assign({ className: cx(classes.topContainer, classes.twoWeeksTopContainer) }, { children: [entryType === ShiftEntryTypes.isBlocked
                        ? _jsx(ArrowUpRight, { "aria-label": 'blocked-icon', className: classes.shiftIcon }, void 0)
                        : renderShiftIcon(), _jsx(Caption, Object.assign({ className: cx(classes.shiftNameBlock, classes.twoWeekViewShiftNameBlock, {
                            [classes.marginRight]: isDifferentFromTemplate,
                        }), variant: 'regular' }, { children: abbreviation || getAbbreviatedName(title) }), void 0)] }), void 0), isResourceView ? (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ className: cx(classes.twoWeeksResourceViewBottomContainer, classes.twoWeeksBottomContainer) }, { children: [_jsxs("div", Object.assign({ className: 'flex items-center text-common-black' }, { children: [_jsxs("div", Object.assign({ className: classes.compactTimeEntry }, { children: [_jsx(Caption, Object.assign({ size: 12, variant: 'regular' }, { children: shiftFromTimeHour }), void 0), _jsx(Caption, Object.assign({ className: classes.compactTimeMinute, size: 8, variant: 'regular' }, { children: shiftFromTimeMinute }), void 0)] }), void 0), _jsx("span", Object.assign({ className: 'text-common-black !m-0 !p-0', style: { lineHeight: 0 } }, { children: "-" }), void 0), ' ', _jsxs("div", Object.assign({ className: classes.compactTimeEntry }, { children: [_jsx(Caption, Object.assign({ size: 12, variant: 'regular' }, { children: shiftToTimeHour }), void 0), _jsx(Caption, Object.assign({ className: classes.compactTimeMinute, size: 8, variant: 'regular' }, { children: shiftToTimeMinute }), void 0)] }), void 0)] }), void 0), _jsx(Body, Object.assign({ className: classes.twoWeekBottomResourceView, size: 12 }, { children: firstName && lastName
                                ? `${String(firstName)} ${String(lastName?.[0])}.`
                                : '-' }), void 0)] }), void 0) }, void 0)) : (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ className: cx(classes.bottomContainer, classes.twoWeeksBottomContainer) }, { children: [_jsxs("div", Object.assign({ className: classes.compactTimeEntry }, { children: [_jsx(Caption, Object.assign({ size: 12, variant: 'regular' }, { children: shiftFromTimeHour }), void 0), _jsx(Caption, Object.assign({ className: classes.compactTimeMinute, size: 8, variant: 'regular' }, { children: shiftFromTimeMinute }), void 0)] }), void 0), _jsx("span", Object.assign({ className: 'text-common-black !m-0 !p-0', style: { lineHeight: 0 } }, { children: "-" }), void 0), ' ', _jsxs("div", Object.assign({ className: classes.compactTimeEntry }, { children: [_jsx(Caption, Object.assign({ size: 12, variant: 'regular' }, { children: shiftToTimeHour }), void 0), _jsx(Caption, Object.assign({ className: classes.compactTimeMinute, size: 8, variant: 'regular' }, { children: shiftToTimeMinute }), void 0)] }), void 0)] }), void 0) }, void 0))] }), void 0));
    const renderOneMonthContent = () => {
        const renderContent = () => {
            if (isCompact) {
                if (isResourceView) {
                    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: cx(classes.topContainer, classes.oneMonthTopContainer, classes.oneMonthCompactTopContainer) }, { children: [renderConfirmationIcon(), _jsx("div", Object.assign({ className: cx(classes.compactTimeContainer, classes.compactTimeContainerOneMonth) }, { children: _jsxs("div", Object.assign({ className: classes.compactTimeEntry }, { children: [_jsx(Caption, Object.assign({ size: 12, variant: 'regular' }, { children: shiftFromTimeHour }), void 0), _jsx(Caption, Object.assign({ className: classes.compactTimeMinute, size: 8, variant: 'regular' }, { children: shiftFromTimeMinute }), void 0)] }), void 0) }), void 0)] }), void 0), _jsxs("div", Object.assign({ className: cx(classes.oneMonthResourceViewBottomContainer, classes.oneMonthBottomContainer, classes.oneMonthCompactBottomContainer) }, { children: [_jsx(Caption, Object.assign({ className: cx(classes.shiftNameBlock, classes.oneMonthShiftNameBlock), variant: 'regular' }, { children: abbreviation || getAbbreviatedName(title) }), void 0), _jsx(Caption, Object.assign({ className: cx(classes.shiftNameBlock, classes.oneMonthShiftNameBlock), variant: 'regular' }, { children: getAbbreviatedName(String(assignedUserFullName)) }), void 0)] }), void 0), entryType === ShiftEntryTypes.isBlocked && (_jsx(ArrowUpRight, { "aria-label": 'blocked-icon', className: classes.oneMonthBlockedIcon }, void 0))] }, void 0));
                }
                return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: cx(classes.topContainer, classes.oneMonthTopContainer, classes.oneMonthCompactTopContainer) }, { children: [renderConfirmationIcon(), _jsx("div", Object.assign({ className: cx(classes.compactTimeContainer, classes.compactTimeContainerOneMonth) }, { children: _jsxs("div", Object.assign({ className: classes.compactTimeEntry }, { children: [_jsx(Caption, Object.assign({ size: 12, variant: 'regular' }, { children: shiftFromTimeHour }), void 0), _jsx(Caption, Object.assign({ className: classes.compactTimeMinute, size: 8, variant: 'regular' }, { children: shiftFromTimeMinute }), void 0)] }), void 0) }), void 0)] }), void 0), _jsx("div", Object.assign({ className: cx(classes.bottomContainer, classes.oneMonthBottomContainer, classes.oneMonthCompactBottomContainer) }, { children: _jsx(Caption, Object.assign({ className: cx(classes.shiftNameBlock, classes.oneMonthShiftNameBlock), variant: 'regular' }, { children: abbreviation || getAbbreviatedName(title) }), void 0) }), void 0), entryType === ShiftEntryTypes.isBlocked && (_jsx(ArrowUpRight, { "aria-label": 'blocked-icon', className: classes.oneMonthBlockedIcon }, void 0))] }, void 0));
            }
            return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: cx(classes.topContainer, classes.oneMonthTopContainer, {
                            [classes.paddingRight]: isDifferentFromTemplate,
                        }) }, { children: entryType === ShiftEntryTypes.isBlocked
                            ? _jsx(ArrowUpRight, { "aria-label": 'blocked-icon', className: classes.shiftIcon }, void 0)
                            : renderShiftIcon() }), void 0), _jsx("div", Object.assign({ className: cx(classes.bottomContainer, classes.oneMonthBottomContainer) }, { children: _jsx(Caption, Object.assign({ className: classes.shiftNameBlock, size: 12, weight: 500 }, { children: abbreviation || getAbbreviatedName(title) }), void 0) }), void 0)] }, void 0));
        };
        return (_jsxs("div", Object.assign({}, rest, { "data-testid": testId, className: cx(classes.container, {
                [classes.isHover]: !cantRenderOverlay() && showHoverActions,
                [classes.isBlocked]: entryType === ShiftEntryTypes.isBlocked,
                [`${String(className)}`]: !!className,
                [classes.flash]: hasFlash,
                [classes.selected]: selected,
                [classes.isFlashMode]: isFlashMode,
            }) }, { children: [renderConfirmationIcon(), renderIsDifferentFromTemplate, !isPublished && _jsx("div", { "aria-label": 'unpublished-entry', className: classes.unPublished }, void 0), renderOverlay(), renderContent()] }), void 0));
    };
    const renderOverlay = () => {
        switch (view) {
            case rangePickerVariantEnum['1_WEEK']:
                return renderOneWeekOverlay();
            case rangePickerVariantEnum['2_WEEK']:
                return renderTwoWeeksOverlay();
            case rangePickerVariantEnum['1_MONTH']:
                return renderMonthOverlay();
            default:
                return _jsx(_Fragment, {}, void 0);
        }
    };
    const renderShiftIcon = () => {
        switch (icon) {
            case shiftIconEnum.BRIEFCASE:
                return _jsx(Briefcase, { "aria-label": 'briefcase-icon', className: classes.shiftIcon }, void 0);
            case shiftIconEnum.CAR:
                return _jsx(Car, { "aria-label": 'car-icon', className: classes.shiftIcon }, void 0);
            case shiftIconEnum.HEADPHONES:
                return _jsx(Headphones, { "aria-label": 'headphones-icon', className: classes.shiftIcon }, void 0);
            case shiftIconEnum.HOME:
                return _jsx(Home, { "aria-label": 'home-icon', className: classes.shiftIcon }, void 0);
            case shiftIconEnum.MOON:
                return _jsx(Moon, { "aria-label": 'moon-icon', className: classes.shiftIcon }, void 0);
            case shiftIconEnum.PHONE_ALT:
                return _jsx(PhoneAlt, { "aria-label": 'phone-alt-icon', className: classes.shiftIcon }, void 0);
            case shiftIconEnum.STAR:
                return _jsx(Star, { "aria-label": 'star-icon', className: classes.shiftIcon }, void 0);
            case shiftIconEnum.SUN:
                return _jsx(Sun, { "aria-label": 'sun-icon', className: classes.shiftIcon }, void 0);
            case shiftIconEnum.SUN_HAZE:
                return _jsx(SunHaze, { "aria-label": 'sun-haze-icon', className: classes.shiftIcon }, void 0);
            case shiftIconEnum.USERS:
                return _jsx(Users, { "aria-label": 'users-icon', className: classes.shiftIcon }, void 0);
            default:
                return _jsx(Briefcase, { "aria-label": 'briefcase-icon', className: classes.shiftIcon }, void 0);
        }
    };
    const renderContent = () => {
        switch (view) {
            case rangePickerVariantEnum['1_WEEK']:
                return renderOneWeekContent();
            case rangePickerVariantEnum['2_WEEK']:
                return renderTwoWeeksContent();
            case rangePickerVariantEnum['1_MONTH']:
                return renderOneMonthContent();
            default:
                return _jsx(_Fragment, {}, void 0);
        }
    };
    if (entryType === ShiftEntryTypes.isBlocked) {
        return (_jsx(Tooltip, Object.assign({ title: blockedTitle || '' }, { children: renderContent() }), void 0));
    }
    return renderContent();
};
