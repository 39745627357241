import { makeStyles } from '@material-ui/core';
// const nextIcon = 'url("data:image/svg+xml,%3Csvg width=\'7\' height=\'10\' viewBox=\'0 0 7 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M6.1142 4.24033C6.57981 4.63942 6.57981 5.35975 6.1142 5.75884L1.65079 9.58462C1.00212 10.1406 1.01432e-07 9.67972 9.12441e-08 8.82537L0 1.17381C-1.01881e-08 0.319454 1.00212 -0.141455 1.65079 0.41455L6.1142 4.24033Z\' fill=\'%237F8897\'/%3E%3C/svg%3E%0A")';
// const previousIcon = 'url("data:image/svg+xml,%3Csvg width=\'7\' height=\'10\' viewBox=\'0 0 7 10\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M0.886318 4.24033C0.420707 4.63942 0.420706 5.35975 0.886318 5.75884L5.34973 9.58462C5.9984 10.1406 7.00052 9.67972 7.00052 8.82537V1.17381C7.00052 0.319454 5.9984 -0.141455 5.34973 0.41455L0.886318 4.24033Z\' fill=\'%237F8897\'/%3E%3C/svg%3E%0A")';
export const useDatePickerStyles = makeStyles((theme) => ({
    arrowBtnIcon: {
        color: theme.palette.grey[500],
    },
    selectYearAndMonth: {
        fontFamily: theme.typography.fontFamily,
        border: 'none',
    },
}));
export const useStyles = makeStyles((theme) => ({
    calendarHeader: {
        margin: 10,
        display: 'flex',
        justifyContent: 'center',
        gap: 10,
    },
    calendarRoot: {
        width: 280,
        height: '100%',
        border: 'none !important',
        background: theme.palette.common.white,
        boxShadow: '0px 16px 32px rgba(22, 36, 61, 0.08)',
        borderRadius: 8,
        userSelect: 'none',
        '& > .react-datepicker__year--container': {
            fontFamily: theme.typography.fontFamily,
            height: '100%',
        },
        // '& > .react-datepicker__navigation--previous': {
        //   width: 10,
        //   top: 22,
        //   left: 35,
        //   content: previousIcon,
        // },
        // '& > .react-datepicker__navigation--next': {
        //   width: 10,
        //   top: 22,
        //   right: 35,
        //   content: nextIcon,
        // },
        '& > .react-datepicker__year--container > .react-datepicker__header, & > .react-datepicker__month-container > .react-datepicker__header': {
            backgroundColor: 'unset',
            border: 'none',
            margin: '20px 0',
            fontWeight: '700',
            fontSize: 15,
            fontFamily: theme.typography.fontFamily,
        },
        '& > .react-datepicker__year--container > .react-datepicker__year ': {
            display: 'flex',
            height: 'calc(100% - 78px)',
            width: '100%',
            margin: 0,
            '& > .react-datepicker__year-wrapper': {
                maxWidth: 'initial',
                margin: '0 12px',
                justifyContent: 'center',
                alignItems: 'center',
                '& > .react-datepicker__year-text': {
                    transition: `all ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeOut}`,
                    fontFamily: theme.typography.fontFamily,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 500,
                    borderRadius: 8,
                    width: 60,
                    height: 36,
                    fontSize: 14,
                    color: theme.palette.common.black,
                    '&--selected': {
                        color: theme.palette.common.white,
                        backgroundColor: theme.palette.primary.main,
                    },
                    '&--keyboard-selected': {
                        backgroundColor: 'unset',
                    },
                    '&--disabled': {
                        color: theme.palette.grey[500],
                    },
                    '&:hover': {
                        color: theme.palette.primary.main,
                        backgroundColor: theme.palette.secondary.dark,
                    },
                },
            },
        },
        '& > .react-datepicker__month-container': {
            width: '100%',
            height: '100%',
            '& > .react-datepicker__header > .react-datepicker__day-names': {
                marginTop: 15,
                '& > div': {
                    color: theme.palette.grey[500],
                    fontFamily: theme.typography.fontFamily,
                },
            },
            '& > .react-datepicker__monthPicker': {
                margin: 0,
                height: 'calc(100% - 77px)',
                display: 'flex',
                flexDirection: 'column',
                '& > .react-datepicker__month': {
                    '&-wrapper': {
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 20,
                        '& > .react-datepicker__month': {
                            '&--disabled': {
                                color: theme.palette.grey[500],
                            },
                            '&--selected': {
                                backgroundColor: theme.palette.primary.main,
                            },
                            '&-text': {
                                transition: `all ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeOut}`,
                                fontFamily: [
                                    'Manrope',
                                    '"Segoe UI"',
                                    'Tahoma',
                                    'Geneva',
                                    'Verdana',
                                    'sans-serif',
                                ].join(','),
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontWeight: 500,
                                borderRadius: 8,
                                width: 60,
                                height: 30,
                                fontSize: 14,
                                '&--keyboard-selected': {
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.common.white,
                                },
                                '&:hover': {
                                    color: theme.palette.primary.main,
                                    backgroundColor: theme.palette.secondary.dark,
                                },
                            },
                        },
                    },
                },
            },
            '& > .react-datepicker__month': {
                marginBottom: 20,
                '& > .react-datepicker__week': {
                    '& > .react-datepicker__day': {
                        fontFamily: theme.typography.fontFamily,
                        fontWeight: 500,
                        fontSize: 14,
                        color: theme.palette.common.black,
                        '&:hover': {
                            color: theme.palette.primary.main,
                            backgroundColor: theme.palette.secondary.dark,
                        },
                        '&--outside-month': {
                            color: theme.palette.grey[500],
                        },
                        '&--keyboard-selected': {
                            backgroundColor: 'unset',
                        },
                        '&--disabled': {
                            color: theme.palette.grey[500],
                        },
                        '&--selected': {
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.common.white,
                        },
                        '&--today': {
                            position: 'relative',
                            '&::after': {
                                position: 'absolute',
                                content: '""',
                                bottom: 0,
                                left: 'calc(50% - 2px)',
                                backgroundColor: theme.palette.primary.main,
                                width: 4,
                                height: 4,
                                borderRadius: '100%',
                            },
                        },
                    },
                },
            },
        },
    },
}));
export const useInputEndAndormentStyles = makeStyles((theme) => ({
    calendarEndAdornment: {
        marginRight: 18,
        '& > svg': {
            transition: `all ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeOut}`,
            color: theme.palette.grey[500],
        },
    },
    calendarEndAdornmentActive: {
        '& > svg': {
            color: theme.palette.primary.main,
        },
    },
}));
