import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import { Body, Button, Caption, Icons, } from '../..';
const useStyles = makeStyles((theme) => ({
    root: {
        padding: '8px 8px 8px 16px',
        backgroundColor: theme.palette.common.white,
        width: '100%',
        overflow: 'hidden',
        borderRadius: 12,
        border: `2px solid ${theme.palette.grey[100]}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 16,
        transition: '0.2s',
        '&:hover': {
            backgroundColor: '#ECF6FF',
        },
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        gap: 16,
    },
    size: {
        weight: 400,
        fontSize: 13,
        lineHeight: '16px',
        color: theme.palette.grey[500],
    },
    fileIcon: {
        color: theme.palette.primary.main,
    },
    downloadIcon: {
        color: theme.palette.grey[500],
    },
}));
export const File = ({ style, className, name, size, url, type = 'download', handleDeleteFile, ...rest }) => {
    const classes = useStyles();
    return (_jsxs("div", Object.assign({ style: style, className: cx(classes.root, className) }, rest, { children: [_jsxs("div", Object.assign({ className: classes.container }, { children: [_jsx(Icons.Files, { className: classes.fileIcon }, void 0), _jsxs("div", { children: [_jsx(Body, Object.assign({ weight: 700 }, { children: name }), void 0), _jsx(Caption, Object.assign({ className: classes.size }, { children: size }), void 0)] }, void 0)] }), void 0), type === 'download' && (_jsx(Button, { variant: 'fixedIcon', onClick: (e) => e.stopPropagation(), icon: (_jsx("a", Object.assign({ href: url, download: true, target: '_blank', rel: 'noreferrer' }, { children: _jsx(Icons.Download, { className: classes.downloadIcon }, void 0) }), void 0)), color: 'ghost' }, void 0)), type === 'delete' && (_jsx(Button, { variant: 'fixedIcon', icon: _jsx(Icons.Trash, { className: classes.downloadIcon }, void 0), color: 'ghost', onClick: handleDeleteFile }, void 0))] }), void 0));
};
