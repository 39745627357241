import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { Dialog as DialogComponent, DialogActions, DialogContent, Slide, makeStyles, } from '@material-ui/core';
import cx from 'classnames';
import { Button } from '../Button';
import { Header, Caption } from '../Typography';
import { Icons, NavigationBar } from '../..';
const Transition = forwardRef((props, ref) => (_jsx(Slide, Object.assign({ direction: 'up', ref: ref }, props), void 0)));
const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: '3px 12px 32px rgba(22, 36, 61, 0.14)',
        borderRadius: 16,
        padding: 32,
    },
    actions: {
        display: 'flex',
        justifyContent: 'center',
        padding: 0,
    },
    content: {
        padding: 0,
        marginBottom: 20,
    },
    titleRoot: {
        marginBottom: 20,
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 4,
        padding: 0,
    },
    closeIcon: {
        color: theme.palette.grey[700],
    },
    text: {
        color: theme.palette.grey[700],
    },
    error: {
        margin: '0 auto 10px',
        color: theme.palette.error.main,
    },
    navigationBar: {
        '& button': {
            marginRight: 16,
        },
    },
    navigationTitleContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    navigationTitle: {
        fontSize: 18,
        lineHeight: '24px',
    },
    navigationSubTitle: {
        color: theme.palette.grey[500],
    },
    navigationBarContent: {
        marginTop: 72,
    },
    redBtn: {
        backgroundColor: theme.palette.error.main,
        '&:hover': {
            backgroundColor: '#f76a71',
        },
        '&:active': {
            backgroundColor: theme.palette.error.main,
        },
    },
}));
export const Dialog = ({ locale, open, handleClose, handleConfirm, handleDecline, handleSecondaryButtonClick, disableHandleKeyDown, isLoading, children, disabled, hideActionButtons = false, hideTopBar = false, headerType = 'text', paperClassName, maxWidth, isConfirmButtonRed, renderAdditionalActions, ...props }) => {
    const classes = useStyles();
    const handleKeyDown = (event) => {
        if (!disableHandleKeyDown && event.key === 'Enter' && handleConfirm && !isLoading && !(disabled || false)) {
            handleConfirm();
        }
    };
    return (_jsx(_Fragment, { children: _jsxs(DialogComponent, Object.assign({}, props, { TransitionComponent: Transition, "data-testid": 'ui-lib-dialog-component', open: open, maxWidth: maxWidth || 'xl', classes: {
                paper: cx([classes.root, paperClassName]),
            }, onClose: handleClose, onKeyDown: handleKeyDown }, { children: [!hideTopBar && (headerType === 'navigationBar' ? (_jsxs(NavigationBar, Object.assign({ className: classes.navigationBar }, { children: [_jsx(Button, { disabled: isLoading, onClick: handleClose, variant: 'fixedIcon', color: 'ghost', "data-testid": 'ui-lib-close-icon-btn', icon: _jsx(Icons.ArrowLeft, { className: classes.closeIcon }, void 0) }, void 0), _jsxs("div", Object.assign({ className: classes.navigationTitleContainer }, { children: [_jsx(Header, Object.assign({ className: classes.navigationTitle }, { children: locale.title }), void 0), _jsx(Caption, Object.assign({ variant: 'regular', className: classes.navigationSubTitle }, { children: locale.subtitle }), void 0)] }), void 0)] }), void 0)) : (_jsxs("div", Object.assign({ className: classes.titleRoot }, { children: [_jsx(Header, Object.assign({ size: 24 }, { children: locale.title }), void 0), _jsxs("div", Object.assign({ className: 'flex items-center' }, { children: [renderAdditionalActions && renderAdditionalActions(), _jsx(Button, { disabled: isLoading, onClick: handleClose, variant: 'fixedIcon', color: 'ghost', "data-testid": 'ui-lib-close-icon-btn', icon: _jsx(Icons.Close, { className: classes.closeIcon }, void 0) }, void 0)] }), void 0)] }), void 0))), _jsx(DialogContent, Object.assign({ id: 'dialog-content', className: cx([
                        classes.content,
                        {
                            [classes.navigationBarContent]: headerType === 'navigationBar',
                        },
                    ]) }, { children: locale?.content || children }), void 0), locale?.errorMessage && (_jsx(Caption, Object.assign({ className: classes.error }, { children: locale.errorMessage }), void 0)), !hideActionButtons && (_jsxs(DialogActions, Object.assign({ className: classes.actions }, { children: [locale.declineButton && (_jsx(Button, { onClick: () => {
                                if (handleDecline) {
                                    handleDecline();
                                }
                                else if (handleClose) {
                                    handleClose();
                                }
                            }, disabled: isLoading, label: locale.declineButton, color: 'ghost', size: 'large', "data-testid": 'ui-lib-close-btn' }, void 0)), locale.secondaryButton && (_jsx(Button, { onClick: handleSecondaryButtonClick, disabled: disabled || isLoading, label: locale.secondaryButton, loading: isLoading, size: 'large' }, void 0)), locale.confirmButton && (_jsx(Button, { onClick: handleConfirm, disabled: disabled || isLoading, label: locale.confirmButton, loading: isLoading, className: isConfirmButtonRed ? classes.redBtn : '', size: 'large' }, void 0))] }), void 0))] }), void 0) }, void 0));
};
