import { useEffect } from 'react';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { ApolloProvider } from '@apollo/client';
import { appWithTranslation } from 'next-i18next';
import { utils } from '@jobdone/jobdone-web-design-system';
import { AnimatePresence } from 'framer-motion';
import Amplify, { Auth } from 'aws-amplify';
import { ToastContainer } from 'react-toastify';
import { LicenseManager } from 'ag-grid-enterprise';
import { RecoilRoot } from 'recoil';
import { useSessionId } from '@common/hooks/useSessionId';
import { useApollo } from '../../lib/apolloClient';
import awsConfig from '../../aws-config';
import 'react-toastify/dist/ReactToastify.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import '../common/global.css';

LicenseManager.setLicenseKey(process.env.NEXT_PUBLIC_AG_GRID_LICENSE_KEY || '');

Amplify.configure(awsConfig);
Auth.configure(awsConfig);

const { ThemeProvider } = utils;

const OpenReplayTracker = dynamic(() => import('../../lib/openReplayTracker'), { ssr: false });

function MyApp({ Component, pageProps }: AppProps) {
  const apolloClient = useApollo(pageProps);

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  const { organizationId } = useSessionId();

  return (
    <ThemeProvider>
      <OpenReplayTracker />
      <ApolloProvider client={apolloClient}>
        <RecoilRoot key={organizationId}>
          <AnimatePresence exitBeforeEnter>
            <Component {...pageProps} />
          </AnimatePresence>
        </RecoilRoot>
      </ApolloProvider>
      <ToastContainer position='bottom-left' pauseOnHover className='break-all' />
    </ThemeProvider>
  );
}

// Welcome to JobDone

export default appWithTranslation(MyApp);
