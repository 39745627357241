import { createElement as _createElement } from "react";
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { useEffect } from 'react';
import { useTable, useSortBy, useExpanded, useRowSelect, } from 'react-table';
import cx from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import InfiniteScroll from 'react-infinite-scroll-component';
import useStyles from './styles/Table.styles';
import IndeterminateCheckbox from './IndeterminateCheckbox';
export function Table({ columns, data, className = 'table-root', tableHeadClassname = 'table-head', onClickRow, getSelectedRows, isMultiSelect, hasTotalCalculationRow = false, isHorizontalScroll = false, isTimeTrackingAndReportsTable = false, isRowHeightMin = false, selectSubRows = false, expanded = {}, infiteScrollHasMore = false, isInfiniteScroll = false, fetchMore = () => { }, infiteScrollLoading = _jsx(_Fragment, {}, void 0), bodyCellCustomClassname, headCellCustomClassname, hasGrandTotalCalculationRow = false, resetCount = 0, ...rest }) {
    const styles = useStyles();
    const _columns = React.useMemo(() => columns, [columns]);
    const _data = React.useMemo(() => data, [data]);
    const tableInstance = useTable({
        columns: _columns,
        data: _data || [],
        autoResetExpanded: false,
        autoResetSelectedRows: false,
        initialState: {
            expanded,
        },
    }, useSortBy, useExpanded, useRowSelect, (hooks) => {
        if (isMultiSelect) {
            // eslint-disable-next-line no-param-reassign
            hooks.getToggleAllRowsSelectedProps = [
                (props, { instance }) => [
                    props,
                    {
                        onChange: () => {
                            instance.rows.forEach((row) => {
                                const _rows = [...instance.rows];
                                _rows.filter((_row) => !!_row.original.isDateRow);
                                row.toggleRowSelected(
                                // eslint-disable-next-line @typescript-eslint/no-shadow
                                !_rows.every((row) => row.isSelected));
                            });
                        },
                        style: { cursor: 'pointer' },
                        checked: instance.rows.every((row) => row.isSelected),
                        title: 'Toggle All Rows Selected',
                        indeterminate: Boolean(!instance.isAllRowsSelected
                            && Object.keys(instance.state.selectedRowIds).length),
                    },
                ],
            ];
            hooks.visibleColumns.push((selectionColumns) => [
                {
                    id: 'row-select',
                    // eslint-disable-next-line react/prop-types
                    Header: ({ getToggleAllRowsSelectedProps }) => (_jsx(_Fragment, { children: _jsx(IndeterminateCheckbox, Object.assign({ indeterminate: getToggleAllRowsSelectedProps().indeterminate }, getToggleAllRowsSelectedProps()), void 0) }, void 0)),
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    Cell: ({ row }) => {
                        if (row.depth > 0) {
                            if (selectSubRows) {
                                return (_jsx(IndeterminateCheckbox, Object.assign({ disabled: row.original.isDateRow || row.original.isEmptyDate }, row.getToggleRowSelectedProps()), void 0));
                            }
                            return null;
                        }
                        return (_jsx(IndeterminateCheckbox, Object.assign({ disabled: row.original.isDateRow || row.original.isEmptyDate }, row.getToggleRowSelectedProps()), void 0));
                    },
                },
                ...selectionColumns,
            ]);
        }
    });
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, selectedFlatRows, toggleAllRowsSelected, } = tableInstance;
    useEffect(() => {
        if (getSelectedRows) {
            getSelectedRows(selectedFlatRows.filter((row) => {
                if (row.original.isDateRow) {
                    return false;
                }
                if (row.original.isEmptyDate) {
                    return false;
                }
                return true;
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFlatRows]);
    useEffect(() => {
        if (resetCount) {
            toggleAllRowsSelected(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetCount]);
    const renderTable = () => (_jsx("div", Object.assign({ className: styles.tableContainer }, { children: _jsx(AnimatePresence, { children: _jsxs(motion.table, Object.assign({ initial: { opacity: 0 }, exit: { opacity: 0 }, animate: { opacity: 1 } }, getTableProps(), { className: cx(styles.table, {
                    [className]: !!className,
                    [styles.tableWithBorderRadius]: hasTotalCalculationRow,
                    [styles.horizontalScroll]: isHorizontalScroll,
                }) }, rest, { children: [_jsx("thead", Object.assign({ className: cx(styles.head, { [tableHeadClassname]: !!tableHeadClassname }) }, { children: headerGroups.map((headerGroup) => (_jsx("tr", Object.assign({}, headerGroup.getHeaderGroupProps(), { children: headerGroup.headers.map((column) => (_createElement("th", { ...column.getHeaderProps({
                                    style: {
                                        width: column.id === 'row-select' ? '50px' : column.width,
                                        minWidth: column.id === 'row-select' ? 0 : column.minWidth,
                                    },
                                }), key: column.id, className: cx(styles.headCell, {
                                    [String(headCellCustomClassname)]: !!headCellCustomClassname,
                                }) }, column.render('Header')))) }), void 0))) }), void 0), _jsxs("tbody", Object.assign({}, getTableBodyProps(), { children: [_jsx("tr", { className: styles.space }, void 0), rows.map((row, i) => {
                                prepareRow(row);
                                if (row.original.isEmptyDate) {
                                    return (_createElement("tr", { ...row.getRowProps(), 
                                        // eslint-disable-next-line react/no-array-index-key
                                        key: `${String(row.original.id)}-${i}`, className: cx(styles.row, styles.isEmptyDate, {
                                            [styles.totalRow]: hasTotalCalculationRow && rows.length - 1 === i,
                                            [styles.noBorderBottom]: rows[i + 1]?.original.isDateRow,
                                        }) }, row.cells.map((cell, cellIndex) => (_jsx("td", Object.assign({}, cell.getCellProps({
                                        style: {
                                            width: cell.column.id === 'row-select' ? '0%' : cell.column.width,
                                            minWidth: cell.column.id === 'row-select' ? 0 : cell.column.minWidth,
                                        },
                                    }), { className: cx(styles.cell, {
                                            [styles.endBalanceColumn]: cell.column.id.includes('endBalance'),
                                            [styles.noPaddingColumn]: cell.column.id.includes('difference'),
                                            [styles.darkerBlue]: rows.length - 1 === i && cell.column.id.includes('endBalance'),
                                            [styles.grandTotalEndBalance]: rows.length - 1 === i && hasGrandTotalCalculationRow && cell.column.id.includes('endBalance'),
                                            [styles.timeTrackingAndReport]: isTimeTrackingAndReportsTable,
                                            [styles.minRowHeight]: isRowHeightMin,
                                            [String(bodyCellCustomClassname)]: !!bodyCellCustomClassname,
                                        }) }, { children: cellIndex >= 5 ? '' : cell.render('Cell') }), void 0)))));
                                }
                                if (row.original.isDateRow) {
                                    return (_createElement("tr", { ...row.getRowProps(), 
                                        // eslint-disable-next-line react/no-array-index-key
                                        key: `${String(row.original.id)}-${i}`, className: cx(styles.row, {
                                            [styles.dateRow]: !!row.original.isDateRow,
                                        }) }, row.cells.map((cell, cellIndex) => (_jsx("td", Object.assign({}, cell.getCellProps({
                                        style: {
                                            width: cell.column.id === 'row-select' ? '0%' : cell.column.width,
                                            minWidth: cell.column.id === 'row-select' ? 0 : cell.column.minWidth,
                                        },
                                    }), { className: cx(styles.cell, {
                                            [styles.endBalanceColumn]: cell.column.id.includes('endBalance'),
                                            [styles.noPaddingColumn]: cell.column.id.includes('difference'),
                                            [styles.darkerBlue]: rows.length - 1 === i && cell.column.id.includes('endBalance'),
                                            [styles.grandTotalEndBalance]: rows.length - 1 === i && hasGrandTotalCalculationRow && cell.column.id.includes('endBalance'),
                                            [styles.rowLeftSide]: cellIndex === 0,
                                            [styles.rowRightSide]: cellIndex === row?.cells.length - 1,
                                            [styles.timeTrackingAndReportDateRow]: isTimeTrackingAndReportsTable,
                                            [String(bodyCellCustomClassname)]: !!bodyCellCustomClassname,
                                        }) }, { children: cellIndex >= 3 ? '' : cell.render('Cell') }), void 0)))));
                                }
                                if (row.original.isEmployeeRow) {
                                    return (_createElement("tr", { ...row.getRowProps(), 
                                        // eslint-disable-next-line react/no-array-index-key
                                        key: `${String(row.original.id)}-${i}`, className: cx(styles.row, {
                                            [styles.employeeRow]: !!row.original.isEmployeeRow,
                                        }) }, row.cells.map((cell, cellIndex) => (_jsx("td", Object.assign({}, cell.getCellProps({
                                        style: {
                                            width: cell.column.id === 'row-select' ? '0%' : cell.column.width,
                                            minWidth: cell.column.id === 'row-select' ? 0 : cell.column.minWidth,
                                        },
                                    }), { className: cx(styles.cell, {
                                            [styles.endBalanceColumn]: cell.column.id.includes('endBalance'),
                                            [styles.noPaddingColumn]: cell.column.id.includes('difference'),
                                            [styles.darkerBlue]: rows.length - 1 === i && cell.column.id.includes('endBalance'),
                                            [styles.grandTotalEndBalance]: rows.length - 1 === i && hasGrandTotalCalculationRow && cell.column.id.includes('endBalance'),
                                            [styles.rowLeftSide]: cellIndex === 0,
                                            [styles.rowRightSide]: cellIndex === row?.cells.length - 1,
                                            [styles.timeTrackingAndReportDateRow]: isTimeTrackingAndReportsTable,
                                            [String(bodyCellCustomClassname)]: !!bodyCellCustomClassname,
                                        }) }, { children: cellIndex >= 3 ? '' : cell.render('Cell') }), void 0)))));
                                }
                                return (_createElement("tr", { ...row.getRowProps(), 
                                    // eslint-disable-next-line react/no-array-index-key
                                    key: `${String(row.original.id)}-${i}`, className: cx(styles.row, {
                                        [styles.totalRow]: hasTotalCalculationRow && rows.length - 1 === i,
                                        [styles.noBorderBottom]: rows[i + 1]?.original.isDateRow,
                                    }), onClick: () => {
                                        if (row.original.secondaryId && row.original.rowType) {
                                            onClickRow(row.original.id, row.original.rowType, row.original.secondaryId);
                                        }
                                        else if (row.original.rowType) {
                                            onClickRow(row.original.id, row.original.rowType);
                                        }
                                        else {
                                            onClickRow(row.original.id);
                                        }
                                    } }, row.cells.map((cell) => (_jsx("td", Object.assign({}, cell.getCellProps({
                                    style: {
                                        width: cell.column.id === 'row-select' ? '0%' : cell.column.width,
                                        minWidth: cell.column.id === 'row-select' ? 0 : cell.column.minWidth,
                                    },
                                }), { className: cx(styles.cell, {
                                        [styles.endBalanceColumn]: cell.column.id.includes('endBalance'),
                                        [styles.noPaddingColumn]: cell.column.id.includes('difference'),
                                        [styles.darkerBlue]: rows.length - 1 === i && cell.column.id.includes('endBalance'),
                                        [styles.grandTotalEndBalance]: rows.length - 1 === i && hasGrandTotalCalculationRow && cell.column.id.includes('endBalance'),
                                        [styles.timeTrackingAndReport]: isTimeTrackingAndReportsTable,
                                        [styles.minRowHeight]: isRowHeightMin,
                                        [String(bodyCellCustomClassname)]: !!bodyCellCustomClassname,
                                    }) }, { children: cell.render('Cell') }), void 0)))));
                            })] }), void 0)] }), void 0) }, void 0) }), void 0));
    if (isInfiniteScroll) {
        return (_jsx(InfiniteScroll, Object.assign({ dataLength: rows.length, next: fetchMore, hasMore: infiteScrollHasMore, loader: infiteScrollLoading }, { children: renderTable() }), void 0));
    }
    return renderTable();
}
