import { absenceColorCodeEnum } from '@jobdone/jobdone-shared-library';
import { makeStyles, alpha } from '@material-ui/core';
const redColorOverlayColor = '#FF858B';
const useStyles = makeStyles((theme) => ({
    container: ({ color = '', isBlocked, selected }) => ({
        position: 'relative',
        borderRadius: selected ? 12 : 8,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        userSelect: 'none',
        border: `${selected ? 2 : 1}px solid ${isBlocked ? theme.palette.grey[300] : color}`,
        marginBottom: 1,
    }),
    isHover: ({ color = '' }) => ({
        '&:hover': {
            transition: theme.transitions.create('border'),
            border: `1px dashed ${color}`,
        },
        '&:hover $overlay': {
            opacity: '1',
            transition: theme.transitions.create('opacity'),
        },
    }),
    overlay: ({ color = '' }) => ({
        boxSizing: 'border-box',
        opacity: '0',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1,
        background: color === absenceColorCodeEnum.RED ? alpha(redColorOverlayColor, 0.9) : alpha(color, 0.9),
        color: theme.palette.common.white,
    }),
    oneWeekOverlay: {
        display: 'flex',
        justifyContent: 'center',
        gap: '8px',
        alignItems: 'center',
    },
    twoWeeksOverlay: {
        zIndex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    oneMonthOverlay: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    overlayButton: {
        border: `2px solid ${theme.palette.common.white}`,
        borderRadius: '8px',
        background: 'transparent',
        color: theme.palette.common.white,
        cursor: 'pointer',
        lineHeight: '0 !important',
        transition: theme.transitions.create(['background', 'color']),
        '&:hover': {
            background: alpha(theme.palette.common.white, 0.8),
            borderColor: 'transparent',
            color: ({ color = '' }) => (color === absenceColorCodeEnum.RED ? alpha(redColorOverlayColor, 0.9) : alpha(color, 0.9)),
        },
        '&:active': {
            background: theme.palette.common.white,
            color: ({ color = '' }) => (color === absenceColorCodeEnum.RED ? alpha(redColorOverlayColor, 0.9) : alpha(color, 0.9)),
        },
    },
    twoWeeksOverlayButton: {
        padding: '1px 2px',
        lineHeight: '0 !important',
        '& svg': {
            width: '20px !important',
            height: '20px !important',
        },
    },
    oneMonthOverlayButton: {
        background: 'transparent',
        color: theme.palette.common.white,
        cursor: 'pointer',
        border: 'none',
        lineHeight: '0 !important',
        '& svg': {
            width: '18px !important',
            height: '18px !important',
        },
    },
    topContainer: ({ color = '', hasFlash = false, selected = false, isFlashMode = false, }) => ({
        background: alpha(color, 0.1),
        display: 'flex',
        borderTopLeftRadius: hasFlash || selected || isFlashMode ? 4 : 0,
        borderTopRightRadius: hasFlash || selected || isFlashMode ? 4 : 0,
    }),
    oneWeekTopContainer: {
        paddingRight: 2,
        height: 20,
        gap: '4px',
        alignItems: 'center',
    },
    twoWeeksTopContainer: {
        paddingRight: 2,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& $shiftIcon': {
            textAlign: 'left',
        },
    },
    twoWeeksTimeMinute: {
        transform: 'translateY(-2px)',
    },
    twoWeeksTimeContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    weekCompactView: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: 8,
    },
    compactViewIconAndTime: {
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        width: '100%',
    },
    compactTimeMinute: {
        transform: 'translateY(-2px)',
    },
    compactViewBlockedIcon: {
        '& svg': {
            width: 20,
            height: 20,
            color: ({ color = '' }) => color,
        },
    },
    compactViewOverlayButton: {
        justifyContent: 'center',
        gap: 8,
        '& button': {
            width: 24,
            height: 24,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& svg': {
                width: 18,
                height: 18,
            },
        },
    },
    compactViewAbsenceName: ({ color = '' }) => ({
        color,
        marginRight: 4,
        textAlign: 'right',
        fontWeight: 500,
        fontSize: '0.8rem',
    }),
    oneMonthTopContainer: {
        justifyContent: 'center',
    },
    oneMonthBlockedIcon: ({ color = '' }) => ({
        position: 'absolute',
        content: '""',
        bottom: 0,
        right: 0,
        color,
        backgroundColor: theme.palette.grey[300],
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        borderTopRightRadius: 4,
    }),
    oneMonthCompactTopContainer: {
        justifyContent: 'left',
        paddingLeft: 2,
    },
    oneMonthShiftNameBlock: {
        justifyContent: 'center',
        textAlign: 'center',
    },
    compactTimeContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    compactTimeEntry: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    compactTimeContainerOneMonth: {
        paddingTop: 4,
        textAlign: 'left',
    },
    bottomContainer: ({ color = '', hasFlash = false, selected = false, isFlashMode = false, }) => ({
        height: 20,
        background: alpha(color, 0.1),
        display: 'flex',
        alignItems: 'center',
        '& > *': {
            color,
        },
        borderBottomLeftRadius: hasFlash || selected || isFlashMode ? 4 : 0,
        borderBottomRightRadius: hasFlash || selected || isFlashMode ? 4 : 0,
    }),
    oneWeekBottomContainer: {
        padding: '0px 8px',
        justifyContent: 'space-between',
    },
    oneMonthBottomContainer: {
        justifyContent: 'center',
        textAlign: 'center',
    },
    oneMonthCompactBottomContainer: {
        textAlign: 'left',
    },
    twoWeeksBottomContainer: {
        padding: '0px 4px',
        justifyContent: 'flex-start',
        '& > span': {
            color: theme.palette.common.black,
        },
    },
    twoWeeksBlockedIcon: {
        textAlign: 'left',
        color: ({ color = '' }) => color,
    },
    isBlocked: {
        '& $topContainer': {
            background: alpha(theme.palette.grey[500], 0.1),
        },
        '& $bottomContainer': {
            background: alpha(theme.palette.grey[500], 0.1),
        },
        '& $unPublished': {
            background: `repeating-linear-gradient( -50deg, ${theme.palette.grey[500]}, ${theme.palette.grey[500]} 20px, transparent 20px, transparent 36px )`,
        },
    },
    twoWeekViewAbsenceNameBlock: ({ color }) => ({
        color,
        textAlign: 'right',
        marginRight: 2,
    }),
    absenceNameBlock: {
        width: '80%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    oneMonthAbsenceNameBlock: {
        textTransform: 'uppercase',
    },
    absenceIcon: ({ color }) => ({
        '& > svg': {
            width: 20,
            height: 20,
        },
        color,
    }),
    unPublished: ({ color = '' }) => ({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        opacity: '0.1',
        background: `repeating-linear-gradient( -50deg, ${color}, ${color} 20px, transparent 20px, transparent 36px )`,
    }),
    flash: {
        '--angle': '0deg',
        border: '4px solid transparent !important',
        boxSizing: 'border-box',
        backgroundOrigin: 'border-box',
        backgroundImage: 'conic-gradient(from var(--angle), #3C5AF9 0%, #F96955 4.69%, #FD87AA 8.85%, #FCD5AD 16.15%, #E97584 23.96%, #FED556 31.77%, #FED655 40.63%, #FFF4F2 48.44%, #F2CDFE 54.69%, #FD99F9 63.54%,  #D693FA 69.79%, #E492E4 77.08%, #1E9AFA 83.85%, #8CFBD7 90.63%, #2E50F6 100%)',
        boxShadow: `2px 1000px 1px ${theme.palette.common.white} inset`,
        animation: '$rotate 2s linear infinite',
    },
    isFlashMode: {
        '& $overlay': {
            background: 'rgba(224, 0, 11, 0.85)',
            margin: 2,
            borderRadius: 4,
            width: 'calc(100% - 4px)',
            height: 'calc(100% - 4px)',
            gap: '2px',
            cursor: 'pointer',
        },
        '&:hover': {
            '--angle': '0deg',
            border: '4px solid transparent !important',
            boxSizing: 'border-box',
            backgroundOrigin: 'border-box',
            backgroundImage: 'conic-gradient(from var(--angle), #3C5AF9 0%, #F96955 4.69%, #FD87AA 8.85%, #FCD5AD 16.15%, #E97584 23.96%, #FED556 31.77%, #FED655 40.63%, #FFF4F2 48.44%, #F2CDFE 54.69%, #FD99F9 63.54%,  #D693FA 69.79%, #E492E4 77.08%, #1E9AFA 83.85%, #8CFBD7 90.63%, #2E50F6 100%)',
            boxShadow: `2px 1000px 1px ${theme.palette.common.white} inset`,
            animation: '$rotate 2s linear infinite',
        },
    },
    '@keyframes rotate': {
        to: {
            '--angle': '360deg',
        },
    },
    trashIcon: {
        height: 18,
        '& svg': {
            height: 18,
        },
    },
    deleteLabel: {
        fontWeight: 700,
        fontSize: '13px',
        lineHeight: '16px',
    },
    selected: {
        boxSizing: 'border-box',
    },
    selectedContainer: ({ color = '' }) => ({
        margin: 2,
        boxSizing: 'border-box',
        borderRadius: 8,
        border: `1px solid ${color}`,
    }),
    assignedUserAvatarBlock: ({ color = '' }) => ({
        border: `1px solid ${color}`,
        marginLeft: 2,
    }),
    marginRight: {
        marginRight: '10px !important',
    },
    paddingRight: {
        paddingRight: '10px !important',
    },
    differentFromTemplate: {
        height: 5,
        width: 5,
        background: theme.palette.grey[500],
        borderRadius: '50%',
        position: 'absolute',
        top: 3,
        right: 3,
    },
    confirmationModeBlock: {
        position: 'absolute',
        zIndex: 1,
        padding: 0.5,
        maxHeight: 17,
        top: '-10px',
        left: '-6px',
        lineHeight: '0 !important',
        '& svg': {
            background: theme.palette.common.white,
            borderRadius: '50%',
            width: 16,
            height: 16,
        },
    },
    confirmationRejected: {
        '& svg path': {
            color: '#FF333D',
        },
    },
}));
export default useStyles;
