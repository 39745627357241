/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';
import {
  ApolloClient, InMemoryCache, NormalizedCacheObject, from, ApolloLink, split,
} from '@apollo/client';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
import { createUploadLink } from 'apollo-upload-client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import Router from 'next/router';
import { AppProps } from 'next/app';
import { catchErrors, interceptRequest, interceptResponse } from './interceptors';
import getAccessToken from './awsGetAccessToken';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

// ? Props are dynamic and different based on the type of props passed in, so any???
type PagePropsWithApollo = AppProps['pageProps'] & {
  [APOLLO_STATE_PROP_NAME]?: NormalizedCacheObject;
};

let apolloClient: ApolloClient<NormalizedCacheObject>;

const uploadLink = createUploadLink({
  uri: process.env.NEXT_PUBLIC_APOLLO_URI,
  credentials: 'same-origin',
});

const createSubscriptionClient = (websocketUrl: string, sendAsHeader: boolean) => {
  const url = websocketUrl;
  return createClient({
    url,
    lazy: true,
    connectionParams: async () => {
      const token = await getAccessToken();
      const sessionId = Router.query?.sessionId;
      const [organizationId, organizationUserId] = (sessionId as string)?.split('.') || [];
      if (sendAsHeader) {
        return {
          headers: {
            Authorization: `Bearer ${String(token)}`,
            Organization: organizationId,
            ...(organizationUserId ? { 'organization-user-id': organizationUserId } : {}),
          },
        };
      }
      return {
        authorization: `Bearer ${String(token)}`,
        organization: organizationId,
        ...(organizationUserId ? { 'organization-user-id': organizationUserId } : {}),
      };
    },
  });
};

const hasuraWebsocketUrl = process.env.NEXT_PUBLIC_HASURA_WS_URI || '';
const apolloWebsocketUrl = process.env.NEXT_PUBLIC_APOLLO_WS_URI || '';

const hasuraWsLink = (typeof window !== 'undefined')
  ? new GraphQLWsLink(createSubscriptionClient(hasuraWebsocketUrl, true))
  : null;

const apolloWsLink = (typeof window !== 'undefined')
  ? new GraphQLWsLink(createSubscriptionClient(apolloWebsocketUrl, false))
  : null;

const createSplitLink = () => {
  if (typeof window === 'undefined') {
    return uploadLink;
  }

  // First, determine if the operation is a subscription
  const subscriptionSplit = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition'
        && definition.operation === 'subscription'
      );
    },
    split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        // Check if the operation name contains "JobDoneWS1"
        return (
          definition.kind === 'OperationDefinition'
          && definition.name?.value.includes('WS1')
        ) ?? false;
      },
      apolloWsLink as unknown as ApolloLink,
      hasuraWsLink as unknown as ApolloLink,
    ),
    // If not a subscription, use the HTTP upload link
    uploadLink as unknown as ApolloLink,
  );

  return subscriptionSplit;
};

const splitLink = createSplitLink();

export function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    // https://github.com/jaydenseric/apollo-upload-client/issues/213
    link: from([catchErrors, interceptRequest, interceptResponse, splitLink] as unknown as ApolloLink[]),
    cache: new InMemoryCache({
      addTypename: false,
    }),
    defaultOptions: {
      query: {
        fetchPolicy: 'network-only',
      },
    },
  });
}

export function initializeApollo(initialState: Partial<{ [x: string]: any; __META: { extraRootIds: string[]; }; }>) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState(
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: PagePropsWithApollo,
) {
  if (pageProps) {
    // eslint-disable-next-line no-param-reassign
    pageProps[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }
  return pageProps;
}

export function useApollo(pageProps: PagePropsWithApollo) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state || {}), [state]);
  return store;
}
